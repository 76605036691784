import React, { useState, createRef } from 'react'
import './scss/App.scss'
import Quiz from 'react-quiz-component'
import 'whatwg-fetch'
import '@babel/polyfill/noConflict'

const locale = require('./conf/locale.json')
let questionList = require('./conf/questions.json')

const dates = [
  { date: '2021-10-04' },
  { date: '2021-10-05' },
  { date: '2021-10-06' },
  { date: '2021-10-07' },
  { date: '2021-10-08' },
]

const isToday = (date) => {
  const today = new Date()

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

// Check if is today and get the index
const getQuizIndex = () => {
  let index = false

  // Get the correct index from date range.
  dates.forEach((date, key) => {
    if (isToday(new Date(date.date))) {
      index = key + 1
    }
  })

  return index
}

// Get the correct quiz.
const quiz = () => {
  if (getQuizIndex() !== false) {
    questionList = require('./conf/wsw_2021/questions_' +
      getQuizIndex() +
      '.json')
  }

  return Object.assign(locale, questionList)
}

const Competition = (props) => {
  const { numberOfQuestions, questions, userInput } = props.obj

  const [isSuccess, setIsSuccess] = useState(false)
  const [message, setMessage] = useState(null)

  const formElementRef = createRef()

  const submitForm = (e) => {
    e.preventDefault()

    window
      .fetch(formElementRef.current.action, {
        method: 'post',
        body: new FormData(formElementRef.current)
      })
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        if (json.status === 'success') {
          setIsSuccess(true)
          setMessage(json.msg)
        } else {
          alert(json.msg)
        }
      })
      .catch((ex) => {
        console.log('parsing failed', ex)
      })
  }

  let correct = 0
  let pointsTotal = 0
  let pointsUser = 0

  questions.forEach((question, index) => {
    const points = Number.parseInt(question.point)

    pointsTotal += points

    // Check answers.
    if (Number.parseInt(question.correctAnswer) === userInput[index]) {
      correct++
      pointsUser += points
    }
  })

  return (
    <div id="competition">
      <h2>Gewinnspiel</h2>

      <p>
        Nach erfolgreicher Teilnahme am Quiz, kann zusätzlich täglich an einem
        Gewinnspiel teilgenommen werden.
      </p>

      {!isSuccess ? (
        <>
          <p>Versuch Dein Glück:</p>

          <form
            action="https://planetarium-wolfsburg.de/competition.php"
            method="post"
            onSubmit={submitForm}
            ref={formElementRef}
          >
            <input type="hidden" name="questions" value={numberOfQuestions} />
            <input type="hidden" name="correct" value={correct} />
            <input type="hidden" name="pointsTotal" value={pointsTotal} />
            <input type="hidden" name="points" value={pointsUser} />

            <div className="firstname">
              <label htmlFor="firstname">Vorname</label>
              <input type="text" name="firstname" id="firstname" required />
            </div>

            <div className="lastname">
              <label htmlFor="lastname">Nachname</label>
              <input type="text" name="lastname" id="lastname" required />
            </div>

            <div className="email">
              <label htmlFor="email">E-Mail</label>
              <input type="email" name="email" id="email" required />
            </div>

            <div className="privacy">
              <input type="checkbox" name="privacy" id="privacy" required />
              <label htmlFor="privacy">
                Ich akzeptiere die&nbsp;
                <a
                  href="https://quiz.planetarium-wolfsburg.de/Teilnahmebedingungen_Datenschutz_WSW-Frauen-im-All.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Teilnahme- und Datenschutzbedingungen
                </a>
                .
              </label>
            </div>

            <input type="submit" value="Teilnehmen" id="participate" />
          </form>
        </>
      ) : (
        <p>{message}</p>
      )}
    </div>
  )
}

const renderCompetition = (obj) => <Competition obj={obj} />

const App = () => {
  return (
    <div className="App">
      {getQuizIndex() && (
        <div className="image-holder">
          <img src="https://quiz.planetarium-wolfsburg.de/wsw.jpg" alt=""/>
          <h1 className="title">World Space Week 2021 - Frauen im All</h1>
        </div>
      )}
      {getQuizIndex() && (
        <Quiz
          quiz={quiz()}
          showDefaultStyle={false}
          customResultPage={renderCompetition}
        />
      )}
    </div>
  )
}

export default App
